import React, {useState} from 'react';
import SocialLinks from '../SocialLinks';
import './Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [buttonText, setButtonText] = useState('ارسال');

  const handleSubmit = async (e)=>{
    e.preventDefault();
    setButtonText('جاري الارسال ...')
    const res = await fetch('https://api.taziz.tech/api/v1/portfolio/contact',{
      method: 'POST',
      headers: {
        'Content-type': "application/json"
      },
      body: JSON.stringify({name,email,message})
    });
    console.log(res.status)
    if(res.status === 200){
      // const data = await res.json();
      setButtonText('تم استلام رسالتك😊');
      document.getElementById('submit').disabled = true;
      setEmail('');
      setMessage('');
      setName('');
    }else{
      setButtonText('حدث خطأ حاول مرة آخرى');
      setTimeout(()=>{
        setButtonText('ارسال');
      }, 2000)
    }

  }
  return (
    <section id="contact">
      <div className="container">
        <div className="heading-wrapper">
          <div className="heading">
            <p className="title">
              ترغب <br />
              التواصل معي ؟
            </p>
            <p className="separator" />
            <p className="subtitle">
              الرجاء استخدام النموذج لارسال رسالة بريد إلكتروني لي {''}
              <span className="mail">
                abdulaziz
                <i className="fas fa-at at" />
                abdulaziz
                <i className="fas fa-circle dot" />
                tech
              </span>
              :
            </p>
          </div>
          <SocialLinks />
        </div>
        <form id="contact-form" onSubmit={handleSubmit}>
          <input placeholder="الاسم" name="name" type="text" required onChange={(e)=> setName(e.target.value)}/>
          <input placeholder="البريد الالكتروني" name="email" type="email" required onChange={(e)=> setEmail(e.target.value)}/>
          <textarea placeholder="الرسالة" type="text" name="message" required onChange={(e)=> setMessage(e.target.value)}/>
          <input className="button" id="submit" value={buttonText} type="submit" />
        </form>
      </div>
    </section>
  );
};

export default Contact;
