import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about">
      <div className="wrapper"> 
        <article>
          <div className="title">
            <h3>من انا ؟</h3>
            <p className="separator" />
          </div>
          <div className="desc full">
            <h4 className="subtitle">اسمي عبدالعزيز</h4>
            <p>
              أنا مطور لتطبيقات الويب وانشاءبرامج تعمل على الانترنت واصمم صفحات جميلة لعرض البرامج
            </p>
            <p>
              وانا استمتع بحل مشاكل البرمجة والبحث عن كل شيء جديد في مجال تقنيات الويب والتطبيقات الحديثة واستخدام تقنيات ذكية لحل المشاكل التي تواجهنا في حياتنا اليومية
            </p>
          </div>
          <div className="title">
            <h3>تعرف علي أكثر</h3>
            <p className="separator" />
          </div>
          <div className="desc">
            <h4 className="subtitle">أنا مبرمج ومطور ويب</h4>
            <p>
              بدأتي برمجتي قبل أكثر من خمس سنوات وتوجهة إلى البرمجة باستخدام java وبدأ بعمل مشاريع خاصة
              وبعد سنوات بدأت اعمل على بايثون python لتطوير برامجي وبدأت أعمل على تطبيقات الويب باستخدام فلاسك و جانقو flask and django 
              واستخدمتهما في انتاج مواقع ومدونات
            </p>
            <p>
              مؤخراً بدأت الانتقال إلى البرمجة باستخدام جافاسكريبت وإلى الآن وانا أعمل بها واعشقها كلغة برمجة
              في الفترة الحالية أسعى جاهداً لاكون فل ستاك fullstack واعمل على التقنيات التاليه
              (Node Express Mongodb React)
            </p>
          </div>
          <div className="desc">
            <h4 className="subtitle">ايضا محب للتصميم</h4>
            <p>
              انا اعمل على تصميم شعاراتي والتعديل على الصور باستخدام Adobe Photoshop و ايضا انتاج رسوماتي الخاصة على 
              adobe Illustrator فانا لا اصنف نفسي مصمم ولكن اذا كنت بحاجة إلى تصميم او تعديل على الصور لا أواجه أي صعوبة
              في استخدام مثل هذه التقنيات
            </p>
            <p>
              لا استمتع كثيراً بتصميم واجهات الويب ولكن اعشق backend أكثر واللوغارتميات التي تعمل في الخلف وطرق حماية المواقع
              وتوفير APIs
            </p>
          </div>
        </article>
      </div>
    </section>
  );
};

export default About;
