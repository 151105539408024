import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header id="welcome-section">
      <div className="forest" />
      <div className="silhouette" />
      <div className="moon" />
      <div className="container">
        <h1>
          <span className="line">أعشق</span>
          <span className="line program">البرمجة</span>
          <span className="line">
            <span className="color">&</span> وبعض التصميم.
          </span>
        </h1>
        <div className="buttons">
          <a href="#projects">اعمالي</a>
          <a href="#contact" className="cta">
            تواصل معي
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
