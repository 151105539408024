import React from 'react';
import Project from './Project';
import './Projects.css';
import tasksTrackingImg from './Images/tasksTracker.jpg';
import recipeAppImg from './Images/recipe-app.jpg';
import todoAppImg from './Images/todoApp.jpg';
import saudiArabiaToursAppImg from './Images/SaudiArabiaTourApp.jpg';
import quotesAppImg from './Images/quotesApp.jpg';
import menuAppImg from './Images/menuApp.jpg';
import colorGeneratorAppImg from './Images/colorGeneratorApp.jpg';
import unsplashAPIImg from './Images/unsplashAPI.jpg';
// import calcoolatorImg from './Images/calcoolatorImg.jpg';


const Projects = props => {
  return (
    <section id="projects">
      <div className="projects-container">
        <div className="heading">
          <h3 className="title">اعمالي</h3>
          <p className="separator" />
          <p className="subtitle">
            سأعرض لكم هنا <u>أغلب</u> المشاريع والاعمال التي انجزتها مؤخراً
            وعملت عليها لتنمية قدراتي في مجال البرمجة وتطوير مواقع الويب.
            
          </p>
        </div>
        <div className="projects-wrapper">
          <Project
            title="برنامج متابعة المهام"
            img={tasksTrackingImg}
            tech="js css react node"
            link="https://abdulaziz003-taskstracker.netlify.app/"
            repo="https://github.com/abdulaziz003/tasks-tracker"
          >
            <small>
              تم عمل التطبيق باستخدام التقنيات التالية: 
              Node CSS React.js and React Router.
            </small>
            <p>
              برنامج لمتابعة مهامك اليومية ومواعيدك باستخدام تقنيات حديثة
            </p>
          </Project>
          <Project
            title="تطبيق وصفات لذيذة"
            img={recipeAppImg}
            tech="js node css react"
            link="https://abdulaziz003-recipe-app.netlify.app/"
            repo="https://github.com/abdulaziz003/recipe-app"
          >
            <small>
            تم استخدام التقنيات التالية لانشاء البرنامج
               Nodejs React CSS React Hooks
            </small>
            <p>
               برنامج يحضر لك وصفات من موقع وصفات طعام بمجرد البحث عن أي وصفة
            </p>
          </Project>
          <Project
            title="قائمة مهامي"
            img={todoAppImg}
            tech="js node css react"
            link="https://abdulaziz003-todo-app.netlify.app/"
            repo="https://github.com/abdulaziz003/todo-app"
          >
            <small>تم عمل البرنامج بالتقنيات التالية : Nodejs React CSS ReactHooks</small>
            <p>
              برنامج يقوم بكتابة مهامك اليومية ووضع علامة منتهي عند المهمة المنتهية ويحفظ كل ما قمت به من مهام
            </p>
          </Project>
          <Project
            title="رحلاتنا السياحية السعودية"
            img={saudiArabiaToursAppImg}
            tech="js node css"
            link="https://abdulaziz003-saudi-tours.netlify.app/"
            repo="https://github.com/abdulaziz003/saudi-arabia-tours-app"
          >
            <small>تم بناء البرنامج باستخدام التقنيات التالية : 
              Nodejs React React Hooks CSS
            </small>
            <p>برنامج لعرض الرحلات السياحية حول المملكة والاسعار مع معلومات عامة حول تلك المناطق</p>
          </Project>
          <Project
            title="اقتباسات جميلة"
            img={quotesAppImg}
            tech="js node css"
            link="https://abdulaziz003-quotes.netlify.app/"
            repo="https://github.com/abdulaziz003/reviews-app"
          >
            <small>تم بناء التطبيق باستخدام التقنيات التالية : React Nodejs CSS Javascript</small>
            <p>
              تطبيق اقتباسات جميلة يعرض اقتباسات رائعة لاشخاص تاريخيين بشكل مرتب أو بطريقة عشوائية
            </p>
          </Project>
          <Project
            title="اطباقنا الرئيسية"
            img={menuAppImg}
            tech="js react node css"
            link="https://abdulaziz003-menu.netlify.app/"
            repo="https://github.com/abdulaziz003/menu-app"
          >
            <small>تم بناء هذا التطبيق باستخدام التقنيات التالية:
              Nodejs Reactjs ReactHooks CSS Javascript
            </small>
            <p>
              تطبيق يعرض قائمة الوجبات المتوفرة لمطعم بشكل جذاب وبالاستطاعة اختيار كل فئة باستقلالية تامة
            </p>
          </Project>
          <Project
            img={colorGeneratorAppImg}
            title="توليد ألوان متدرجة"
            tech="js react node css"
            link="https://abdulaziz003-colorgenerator.netlify.app/"
            repo="https://github.com/abdulaziz003/color-generator"
          >
            <small>تم بناء هذ التطبيق باستخدام التقنيات التالية:
              Reactjs Nodejs CSS Javascript
            </small>
            <p>
             تطبيق الهدف منه مساعدة المصممين في استخراج ألوان متدرجة والالوان المناسبة للخط الأبيض أو الاسود
            </p>
          </Project>
          <Project
            title="استخدام API للبحث بموقع unsplash"
            img={unsplashAPIImg}
            tech="js react css node"
            link="https://abdulaziz003-unsplash.netlify.app/"
            repo="https://github.com/abdulaziz003/unsplash-photos-api"
          >
            <small>تم بناء التطبيق باستخدام التقنيات التالية: 
              Reactjs ReactHooks Nodejs CSS Javascript
            </small>
            <p>البحث عن الصور الرائعة على الموقع الشهير unsplash.com وعرض الصور بطريقة منظمة وتحميل الصور كلما تم النزول في الصفحة</p>
          </Project>
        </div>
      </div>
    </section>
  );
};

export default Projects;
