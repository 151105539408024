import {useState, useEffect} from 'react';
import Menu from './Components/Menu/Menu';
import Nav from './Components/Nav/Nav';
import Header from './Components/Header/Header';
import About from './Components/About/About';
import Projects from './Components/Projects/Projects';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import ReactGA from 'react-ga';


function App() {
  const [menuString, setmenuString] = useState("deactive");
  const [menuState, setmenuState] = useState(false);

  function navSmoothScrolling() {
    const internalLinks = document.querySelectorAll('a[href^="#"]');
    for (let i in internalLinks) {
      if (internalLinks.hasOwnProperty(i)) {
        internalLinks[i].addEventListener('click', e => {
          e.preventDefault();
          document.querySelector(internalLinks[i].hash).scrollIntoView({
            block: 'start',
            behavior: 'smooth'
          });
        });
      }
    }
  }




  useEffect(() => {
    ReactGA.initialize('G-V26E57N7VR');
    ReactGA.pageview('/');

    const navbar = document.querySelector('#navbar');
    const header = document.querySelector('#welcome-section');
    const forest = document.querySelector('.forest');
    const silhouette = document.querySelector('.silhouette');
    let forestInitPos = -300;

    window.onscroll = () => {
      let scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollPos <= window.innerHeight) {
        silhouette.style.bottom = `${parseInt(scrollPos / 6)}px`;
        forest.style.bottom = `${parseInt(forestInitPos + scrollPos / 6)}px`;
      }

      if (scrollPos - 100 <= window.innerHeight)
        header.style.visibility = header.style.visibility === 'hidden' && 'visible';
      else header.style.visibility = 'hidden';

      if (scrollPos + 100 >= window.innerHeight) navbar.classList.add('bg-active');
      else navbar.classList.remove('bg-active');
    };

    navSmoothScrolling();
  }, [])
  
  const toggleMenu = ()=>{
    if(menuState){
      setmenuState(false);
      setmenuString('deactive')
    }else{
      setmenuState(true);
      setmenuString('active')
    }
  }
  
  return (
    <>
      <Menu toggleMenu={toggleMenu} showMenu={menuString} />
      <Nav toggleMenu={toggleMenu} showMenu={menuString} />
      <Header />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
